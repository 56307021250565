<template>
  <div>
    <button type="button" class="venmo-info" @click="toggle">Venmo</button>
    <div class="venmo" :class="show ? 'venmo-show' : ''">
      <a class="link" target="_blank"
         href="https://help.venmo.com/hc/en-us/articles/360061922654-Buying-Cryptocurrency-on-Venmo">
        If you do not have Bitcoin in your Venmo account, here is how to buy within Venmo: CLICK HERE
      </a>
      <br/>
      <em>How do I buy crypto in the Venmo app?</em> <br/>

      1. Go to the <b>Crypto</b> tab<br/>
      2. Select your preferred cryptocurrency - you’ll see the current price along with an option to buy <br/>
      3. Enter the dollar amount for your purchase and hit <b>Review</b> <br/>
      4. Your app will show you a breakdown of how much of the cryptocurrency you’re about to buy, along with any fees
      <br/>
      5. Tap <b>Buy</b> to complete the purchase <br/>
      <em class="link" @click="buy">When paying with Venmo you still need to pay via bitcoin - PURCHASE HERE.</em> <br/>
      <br/>
      <em>
        * Please note that Venmo has minimum transfer amounts for cryptocurrency transactions. If your balance does not
        meet these minimums, you will not be able to complete the payment:</em>
      <ul>
        <li>Bitcoin (BTC): 0.001 BTC</li>
        <li>Ethereum (ETH): 0.01 ETH</li>
        <li>Bitcoin Cash (BCH): 0.01 BCH</li>
        <li>Litecoin (LTC): 0.01 LTC</li>
        <li>PYUSD (Ethereum): 10 PYUSD</li>
        <li>PYUSD (Solana): 1 PYUSD</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CVenmoInfo',
  data: function () {
    return {
      show: false
    }
  },
  methods: {
    toggle () {
      this.show = !this.show
    },
    buy () {
      this.$emit('buy')
    }
  }
}
</script>

<style lang="scss">
.venmo-info {
  background: #fff;
  color: $primary;
  border: solid 1px $primary !important;
  font-size: 1.15em;
  border-radius: 4px;
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  max-width: 750px;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  border: none;
  vertical-align: top;
  cursor: pointer;
  overflow: hidden;
}

.venmo {
  display: none;
  background: #fff;
  border-radius: 8px;
  padding: 1em;
  border: solid 1px $primary !important;
}

.venmo-show {
  display: block;
}
</style>
